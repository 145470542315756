// src/components/DropdownMenu.js
import React from 'react';
import Select from 'react-select';

const DropdownMenu = ({ options, onChange, header, isRequired, value }) => {

  return (
    <div className="form-group">
      {header && <label>{header}</label>}
      <Select value={value} options={options} onChange={onChange} isRequired={isRequired} />
    </div>
  );
};

export default DropdownMenu;
