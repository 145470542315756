// src/components/Form.js
import React, { useState, useEffect } from 'react';
import DropdownMenu from './components/DropdownMenu';
import PhoneNumberInput from './components/PhoneNumberInput';
import ReminderPopup from './components/ReminderPopup';
import CookieDisclaimerPopup from './components/CookieDisclaimerPopup';
import './App.css'; // Import the stylesheet
import axios from 'axios';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const images = [
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/background.jpg?alt=media&token=2c2880af-5d0a-4e26-a842-995ad97a26e2',
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/background1.jpg?alt=media&token=840faf09-cc6a-449b-aebe-415a19ad61d0',
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/background2.jpg?alt=media&token=2ec58751-a3a4-4ba1-8239-ac3843c54f41',
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/background3.jpg?alt=media&token=06642bf8-0d7c-458f-9446-dd25b5375180',
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/background4.jpg?alt=media&token=b8c0a2a3-a53d-4a60-9289-72c474c90b38',
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/background5.jpg?alt=media&token=6660f625-a1e7-4395-b492-d7a00e926020',
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/background6.jpg?alt=media&token=0e4d560d-1fe4-4328-a08d-832e4ae6d240',
  'https://firebasestorage.googleapis.com/v0/b/anime-reminder-fbd71.appspot.com/o/wp11472131-spy-x-family-pc-wallpapers.jpg?alt=media&token=fab62d81-bff0-4d06-8817-029d493e9001'
];

var randomImage = images[Math.floor(Math.random() * images.length)];

const decrypt = (cipher) => {
  const bytes = CryptoJS.AES.decrypt(cipher, process.env.REACT_APP_CRYPTR_SECRET);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
};

const getaccesstoken = async () => {
  return( await fetch("/mongo_login").then(response => {
    if (!response.status === 302) {
      console.error(`Did not get 302 status.`);
  }
  return response.text();
  })
  .then((response) => {
    return response;
})
.catch(err => console.log(err))
)};

let accesstoken = await getaccesstoken();

var showOptions;
var showurl; 


await fetch("/get_entries",{
  headers: new Headers({
    'Content-Type': 'application/json',
   }),
  method: "post"
})
.then(async response => {
  var resp = JSON.parse(await response.text());
  showOptions = resp["Options"]
  showurl = resp["Url"]
})
.catch(function (error) {
});

const Form = () => {
  const [selectedshow, setSelectedshow] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [dialCode, setdialCode] = useState(0);
  const [phoneCountry, setphoneCountry] = useState('');
  const [gatewaydetails, setgatewaydetails] = useState([{}]);
  const [showPopup, setShowPopup] = useState(false); // State for showing/hiding the popup
  const [newShowName, setNewShowName] = useState(''); // State for show name input
  const [newShowUrl, setNewShowUrl] = useState(''); // State for URL input
  const [authenticationPhrase, setAuthenticationPhrase] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State for authentication status
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for success popup
  const [showErrorPopup, setShowErrorPopup] = useState(false); // State for error popup
  const [ErrorPopup, setErrorPopup] = useState('Submission Error. Please try again.'); // State for error popup
  const [showManageReminders, setShowManageReminders] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [rep, setrep] = useState('');
  const [userdata, setuserdata] = useState({phoneNumber: null, rep: "", selectedCarrier: null})

  useEffect(() => {
    // Check if the user has accepted cookies
    const cookiesAccepted = Cookies.get('AnimeWatchDog_Accepted');
    if (!cookiesAccepted) {
      // If cookies have not been accepted, show the disclaimer popup
      setShowDisclaimer(true);
    }
    if (cookiesAccepted) {
      // Automatically log in the user using stored information
      const userDataCookie = Cookies.get('AnimeWatchDog_UserData');
      if (userDataCookie) {
        const parsedUserData = JSON.parse(decrypt(userDataCookie));
        setuserdata(parsedUserData);
        setPhoneNumber(parsedUserData['phoneNumber']);
        setSelectedCarrier(parsedUserData['selectedCarrier']);
        setrep(parsedUserData['rep']);
      }
    }
  }, []);

  const handleCloseReminder = () => {
    const cookiesAccepted = Cookies.get('AnimeWatchDog_Accepted');
    if (!cookiesAccepted) {
      // If cookies have not been accepted, show the disclaimer popup
      setShowDisclaimer(true);
    }
    if (cookiesAccepted) {
      // Automatically log in the user using stored information
      const userDataCookie = Cookies.get('AnimeWatchDog_UserData');
      if (userDataCookie) {
        const parsedUserData = JSON.parse(decrypt(userDataCookie));
        setuserdata(parsedUserData);
        setPhoneNumber(parsedUserData['phoneNumber']);
        setSelectedCarrier(parsedUserData['selectedCarrier']);
        setrep(parsedUserData['rep']);
      }
    }
    setShowManageReminders(false);
  }

  const handleCloseDisclaimer = () => {
    // Close the disclaimer popup
    setShowDisclaimer(false);
  };
  
  // Function to check authentication
  const checkAuthentication = (phrase) => {
    if (phrase === process.env.REACT_APP_PHRASE_KEY) {
      // Authentication successful
      return true;
    }
    // Authentication failed
    return false;
  };

  // Function to handle authentication input change
  const handleAuthenticationInputChange = (value) => {
    setAuthenticationPhrase(value);
    setIsAuthenticated(checkAuthentication(value));
  };

  // Function to handle adding a new show
  const handleAddShow = async () => {
    if (isAuthenticated) {

      await fetch("/add_to_db",{
        headers: new Headers({
          'Content-Type': 'application/json',
         }),
        method: "POST",
        body: JSON.stringify({show: newShowName, url: newShowUrl})
      }).then(response => {
        if (response.ok) {
            // After adding the show, you can close the popup and reset the input fields
            setNewShowName('');
            setNewShowUrl('');
            setAuthenticationPhrase('');
            setIsAuthenticated(false);
            setShowPopup(false);
        }
      })     
    }
  };

  const handleshowChange = (show) => {
    setSelectedshow(show);
  };

  const handleCarrierChange = (carrier) => {
    setSelectedCarrier(carrier);
  };

  const handlePhoneNumberChange = async (value, show, e, formattedValue) => {
    if (show['name'] !== phoneCountry){
      setphoneCountry(show['name']);

      await fetch("/get_carrier_info",{
        headers: new Headers({
          'Content-Type': 'application/json',
         }),
        method: "POST",
        body: JSON.stringify({Country: show['name']})
      }).then(async response => {
        var resp = JSON.parse(await response.text());
        setgatewaydetails(resp["Gateway"])
        setCarrierOptions(resp["Carrier"])
      })
      .catch(function (error) {
      });
    }
    setdialCode(show['dialCode'].length);
    setPhoneNumber(value);
  };

  const handleSubmit = async () => {
    const errors = {};
    if (!selectedshow) {
      errors.show = 'Show is required';
    }
    if (!phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } 
    if (!selectedCarrier) {
      errors.carrier = 'Carrier is required';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      var recipient = rep!=='' ? rep : gatewaydetails.find(item => item.hasOwnProperty(selectedCarrier.label))[selectedCarrier.label].replace('number', phoneNumber.slice(dialCode));
      var url = showurl.find(item => item.hasOwnProperty(selectedshow.label))[selectedshow.label];


      await fetch("/db_add_entry",{
        headers: new Headers({
          'Content-Type': 'application/json',
         }),
        method: "POST",
        body: JSON.stringify({label: selectedshow.label, url: url, user: recipient})
    }).then(async response => {
      const isSuccess = response.status === 201; // Set this based on the server response
      
      if (isSuccess) {
        var welcome_data = JSON.stringify({
          "recipient": recipient,
        })
        var welcome_config = {
          method: 'post',
          url: 'https://msb.prakrut.dev/welcome_new_user',
          headers: {
            'Content-Type': 'application/json',
          },
          data: welcome_data
        };
        await axios(welcome_config);
        // Show success popup and reset the fields
        setShowSuccessPopup(true);
        setNewShowName('');
        setNewShowUrl('');
        setSelectedCarrier(userdata['selectedCarrier']);
        setPhoneNumber(userdata['phoneNumber']);
        setrep(userdata['rep']);
        setIsAuthenticated(false);
        setSelectedshow(null);
        setdialCode(0);
        setgatewaydetails([{}]);
        setAuthenticationPhrase('');


        // Hide success popup after 3 seconds
        setTimeout(() => {
          setShowSuccessPopup(false);
        }, 3000);
      } else {
        // Show error popup
        setShowErrorPopup(true);
        
        // Hide error popup after 3 seconds
        setTimeout(() => {
          setShowErrorPopup(false);
        }, 3000);
      }
      })
      .catch(function (error) {
        // Show error popup
        if (error.response.data.includes('Duplicate key error')) {
          setErrorPopup('Already Signed Up');
        }
        setShowErrorPopup(true);

        // Hide error popup after 3 seconds
        setTimeout(() => {
          setShowErrorPopup(false);
        }, 3000);
      });
    }
  };
  useEffect(() => {
    if (showSuccessPopup || showErrorPopup) {
      // Clear the popups after 3 seconds
      const popupTimeout = setTimeout(() => {
        setShowSuccessPopup(false);
        setShowErrorPopup(false);
      }, 3000);

      // Clear the timeout on component unmount
      return () => clearTimeout(popupTimeout);
    }
  }, [showSuccessPopup, showErrorPopup]);


  return (
    <div>
      {/* "Manage Reminders" button */}
      <button className="manage-reminders-button" onClick={() => setShowManageReminders(true)}>
        Manage Reminders
      </button>

      {/* ReminderPopup component */}
      {showManageReminders && <ReminderPopup accesstoken={accesstoken} onClose={() => handleCloseReminder()} />}

      {/* Form component */}
      <div className="form-wrapper" style={{ backgroundImage: `url(${randomImage})` }}>
      {!showManageReminders && (
        <div className="form-container">
          <h2 className="form-title">New Episode Reminder</h2>
          {/* Add button to toggle the popup */}
          <button className="add-button" onClick={() => setShowPopup(true)}>
            Add Show
          </button>

          {/* Popup container */}
          {showPopup && (
            <div className="popup-container">
              <div className="popup-content">
                <label>Show Name:</label>
                <input
                  type="text"
                  value={newShowName}
                  onChange={(e) => setNewShowName(e.target.value)}
                />
                <label>Show URL:</label>
                <input
                  type="text"
                  value={newShowUrl}
                  onChange={(e) => setNewShowUrl(e.target.value)}
                />

                {/* Authentication Phrase Input */}
                <label>Authentication Phrase:</label>
                <input
                  type="password"
                  value={authenticationPhrase}
                  onChange={(e) => handleAuthenticationInputChange(e.target.value)}
                />

                {/* Display authentication status */}
                <p className={isAuthenticated ? 'success-message' : 'error-message'}>
                  {isAuthenticated ? 'Authentication Successful' : 'Authentication Failed'}
                </p>

                {/* Add button */}
                <button
                  onClick={handleAddShow}
                  disabled={!isAuthenticated} // Disable the button if not authenticated
                >
                  Add
                </button>
              </div>
            </div>
          )}

          {/* Success Popup */}
          {showSuccessPopup && (
            <div className="popup success-popup">
              <p>Submission Successful</p>
            </div>
          )}

          {/* Error Popup */}
          {showErrorPopup && (
            <div className="popup error-popup">
              <p>{ErrorPopup}</p>
            </div>
          )}

          {/* Error messages for form validation */}
          <div className="error-text">{formErrors.show}</div>
          <div className="error-text">{formErrors.phoneNumber}</div>
          <div className="error-text">{formErrors.carrier}</div>

          {/* Dropdown for selecting show */}
          <label className="dropdown-label">Select Show</label>
          <DropdownMenu value={selectedshow} options={showOptions} onChange={handleshowChange} />

          {/* Input field for phone number */}
          <label className="dropdown-label">Phone Number</label>
          <PhoneNumberInput value={phoneNumber} onChange={handlePhoneNumberChange} />

          {/* Dropdown for selecting carrier */}
          <label className="dropdown-label">Select Carrier</label>
          <DropdownMenu value={selectedCarrier} options={carrierOptions} onChange={handleCarrierChange} />

          {/* Submit button */}
          <div className="submit-button">
            <button className="submit-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
        )}   
      </div>
      <div className='disclaimer'>
          {showDisclaimer && <CookieDisclaimerPopup onClose={handleCloseDisclaimer} />}
      </div>
    </div>
  );
};


export default Form;
