// src/components/PhoneNumberInput.js
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import startsWith from 'lodash.startswith';

const PhoneNumberInput = ({ value, onChange, isRequired  }) => {
  return (
    <div className="form-group">
      <PhoneInput
        inputClass="form-control" // Apply Bootstrap class
        value={value}
        onChange={onChange}
        preferredCountries={['us']}
        isRequired={isRequired}
        isValid={(inputNumber, country, countries) => {
          return countries.some((country) => {
            return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
          });
        }}
      />
    </div>
  );
};

export default PhoneNumberInput;
