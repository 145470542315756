// CookieDisclaimerPopup.js
import React from 'react';
import Cookies from 'js-cookie';

const CookieDisclaimerPopup = ({ onClose }) => {
  const handleAccept = () => {
    // Set a cookie to indicate that the user has accepted the disclaimer
    Cookies.set('AnimeWatchDog_Accepted', 'true', { expires: 365 }); // Set cookie expiration for 1 year
    onClose(); // Close the disclaimer popup
  };

  return (
    <div className="cookie-disclaimer-popup">
      <div className="cookie-disclaimer-content">
        This website uses cookies to ensure you get the best experience on our website.
        <button className="cookie-disclaimer-accept-btn" onClick={handleAccept}>
        Accept
      </button>
      </div>
      
    </div>
  );
};

export default CookieDisclaimerPopup;
